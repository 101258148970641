export const paymentTypeMap = {
    // 付款方式
    "PAYMENT_TYPE_CASH_ON_DELIVERY": 'cash_on_delivery', // 貨到付款
    "PAYMENT_TYPE_CASH_ON_REMIT": 'cash_on_remit', // 貨到匯款
    "PAYMENT_TYPE_PRE_REMIT": 'pre_remit', // 預先匯款
    "PAYMENT_TYPE_WEEKLY": "weekly", // 周結
    "PAYMENT_TYPE_MONTHLY": "monthly",  // 月結
    "PAYMENT_TYPE_PAY_FIRST": "pay_first", // 匯款後出貨
    "PAYMENT_TYPE_LINEPAY": 'linepay', // LinePay
    "PAYMENT_TYPE_ECPAY": 'ecpay', // ECPay
}

// @ts-ignore
export const paymentTypes = Object.keys(paymentTypeMap).map((key:string) => paymentTypeMap[key])